import { DataSharingPreference } from '../../../utils/constants';
import { supabase } from '../SupabaseClient';

export const getArtistTotalFansCount = async (
  artistId,
  artistSpotifyId,
  artistAppleId,
  filters
) => {
  if (!artistId || !artistSpotifyId) return;

  const query = supabase.from('tbl_staged_fan_user_profile').select('*', { count: 'exact' });

  const finalQuery = createfilteredQuery(query, artistId, artistSpotifyId, artistAppleId, filters);
  const results = await finalQuery;
  return results;
};

export const getArtistFansList = async (
  artistId,
  artistSpotifyId,
  artistAppleId,
  filters,
  currentPage = 1,
  limit = 5
) => {
  if (!artistId || !artistSpotifyId) return;
  let query = supabase.from('tbl_staged_fan_user_profile').select();

  let finalQuery = createfilteredQuery(query, artistId, artistSpotifyId, artistAppleId, filters);
  finalQuery = finalQuery.range((currentPage - 1) * limit, (currentPage - 1) * limit + (limit - 1));
  const results = await finalQuery;
  return results?.data;
};

const createfilteredQuery = (query, artistId, artistSpotifyId, artistAppleId, filters) => {
  const notNullFilters = filters?.filter((e) => e.type === 'not')?.map((e) => e.name);
  const sortingFields = filters?.filter((e) => e.type === 'order')?.map((e) => e.name);
  const fieldFilters = filters?.filter((e) => e.type === 'filter')?.map((e) => e.name);

  query = query.not(
    'data_sharing_preference',
    'in',
    `(${DataSharingPreference.SHARE_NO_DATA_WITH_FOLLOWED})`
  );
  if (notNullFilters?.length) {
    notNullFilters.forEach((filter) => {
      query = query.not(filter, 'is', null);
    });
  }
  if (sortingFields?.length) {
    query = query.order(sortingFields);
  } else {
    query = query.order(['fan_name']);
  }
  if (fieldFilters?.length) {
    fieldFilters.forEach((filter) => {
      if (filter === 'favourite_1') {
        query = query.eq('favourite_1', artistId);
      } else if (filter === 'top5Artists') {
        query = query.or(
          `favourite_1.eq.${artistId},favourite_2.eq.${artistId},favourite_3.eq.${artistId},favourite_4.eq.${artistId},favourite_5.eq.${artistId}`
        );
      } else if (filter === 'email' || filter === 'push' || filter === 'sms') {
        query = query.contains('custom_channel_list', [filter]);
      }
    });
  } else {
    query = query.or(
      `favourite_1.eq.${artistId},favourite_2.eq.${artistId},favourite_3.eq.${artistId},favourite_4.eq.${artistId},favourite_5.eq.${artistId},spotify_followed_artists_id.cs.{${artistSpotifyId}},apple_followed_artists_id.cs.{${artistAppleId}}`
    );
  }
  return query;
};
