import { Route, Routes } from 'react-router-dom';
import {
  AboutView,
  ActivityPage,
  ArtistProfilePage,
  CreatePostPage,
  EditorProfilePage,
  FanDataPage,
  FanStatisticsPage,
  ManageDataPage,
  ManageProfilePage,
  MessagesPage,
  PartneringView,
} from '../pages';

/**
 * List of routes available only for authenticated users
 * @component PrivateRoutes
 */
const PrivateRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<ArtistProfilePage />} />
      <Route path="/activity" element={<ActivityPage />} />
      <Route path="/partnering" element={<PartneringView />} />
      <Route path="/manage-data" element={<ManageProfilePage />} />
      <Route path="/fan-analytics" element={<FanStatisticsPage />} />
      <Route path="/messages" element={<MessagesPage />} />
      <Route path="/create-post" element={<CreatePostPage />} />
      <Route path="/manage-profile" element={<ManageDataPage />} />
      <Route path="/profile" element={<EditorProfilePage />} />
      <Route path="/fan-data" element={<FanDataPage />} />
      <Route path="/about" element={<AboutView />} />
    </Routes>
  );
};

export default PrivateRoutes;
