export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_CURRENT_USER_MAPPING = 'SET_CURRENT_USER_MAPPING';
export const SET_USER_IP_DATA = 'SET_USER_IP_DATA';
export const SET_SELECTED_ARTIST = 'SET_SELECTED_ARTIST';
export const SET_SELECTED_ARTIST_IMAGE = 'SET_SELECTED_ARTIST_IMAGE';
export const SIGN_UP = 'SIGN_UP';
export const LOG_IN = 'LOG_IN';
export const LOG_OUT = 'LOG_OUT';
export const DARK_MODE = 'DARK_MODE';
export const SET_ERROR = 'SET_ERROR';
