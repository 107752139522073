import { Route, Routes } from 'react-router-dom';
import { WelcomePage } from '../pages';
import AuthRoutes from '../pages/Auth';
/**
 * List of routes available only for anonymous users
 * @component PublicRoutes
 */
const PublicRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<WelcomePage />} />
      <Route path="auth/*" element={<AuthRoutes />} />
    </Routes>
  );
};

export default PublicRoutes;
