import { useEffect, useRef, useState } from 'react';

import { Stack, Typography } from '@mui/material';
import { useAppStore } from '../../store/AppStore';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import UserIcon from '../../assets/icons/account.png';
import MenuItem from '@mui/material/MenuItem';
import {
  useEventLogout,
  useEventSwitchDarkMode,
  useIsAuthenticated,
  useOnMobile,
} from '../../hooks';
import { api } from '../../api';
import { AppIcon } from '../../components';

const ArtistDelegation = ({
  userMapping,
  selectedArtist,
  setSelectedArtist,
  selectedArtistImage,
  setSelectedArtistImage,
}) => {
  const [state] = useAppStore();

  const [delegatedArtists, setDelegatedArtists] = useState([]);
  const [labelManagedArtists, setLabelManagedArtists] = useState([]);
  const [artists, setArtists] = useState([]);
  const [artistImages, setArtistImages] = useState([]);

  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const buttonRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      if (loading) return;
      setLoading(true);

      if (userMapping) {
        if (
          userMapping?.editor_delegated_artists?.length ||
          userMapping?.fandata_delegated_artists?.length ||
          (userMapping?.is_label_group_manager && userMapping?.label_group_code)
        ) {
          const artistsList = await api.db.artists.list.getDelegatedArtists(
            userMapping.label_group_code,
            [
              ...(userMapping.editor_delegated_artists || []),
              ...(userMapping.fandata_delegated_artists || []),
            ]
          );
          const rtsvrfArtistIds = artistsList?.map((artist) => artist?.rtsvrf_artist_id) || [];
          setArtists(artistsList);
          const images = await api.db.artists.getArtistImages(rtsvrfArtistIds);
          setArtistImages(images);
          if (artistsList.length) {
            const artistImage = images.find(
              (img) => img.rtsvrf_artist_id === artistsList[0]?.rtsvrf_artist_id
            );
            setSelectedArtistImage(
              artistImage ? artistImage[artistImage.artist_picture_preference] : null
            );
            setSelectedArtist({
              ...artistsList[0],
            });
          }
        } else {
          setDelegatedArtists([]);
          setArtistImages([]);
        }
      }

      setLoading(false);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userMapping]);

  useEffect(() => {
    // Function to close the menu when clicking outside
    const handleClickOutside = (event) => {
      if (buttonRef.current && !buttonRef.current.contains(event.target)) {
        setAnchorEl(null);
      }
    };

    // Add event listener when the menu is open
    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      // Remove event listener when the menu is closed
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      // Clean up the event listener
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleArtistSelection = async (artist, artistImage) => {
    if (!artist?.artist_suprsend_subscriber_id) {
      const artist_suprsend_subscriber_id = await api.suprsend.getUserSubscriberKey(
        artist.rtsvrf_artist_id
      );
      console.log(artist_suprsend_subscriber_id);
      if (artist_suprsend_subscriber_id) {
        await api.db.artists.profile.updateArtistProfile(artist.rtsvrf_artist_id, {
          ...artist,
          artist_suprsend_subscriber_id: artist_suprsend_subscriber_id,
        });
      }
      artist = { ...artist, artist_suprsend_subscriber_id };
    }
    setSelectedArtistImage(artistImage);
    setSelectedArtist({ ...artist });
    handleClose();
  };

  return (
    <Stack className="artist-delegation">
      <Stack
        id="basic-button"
        sx={{
          border: '1px solid #2C2D2D1A',
          background: 'white',
          textTransform: 'none',
          color: 'black',
          borderRadius: '20px',
          padding: '5px',
          textAlign: 'left',
        }}
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        ref={buttonRef}
      >
        <Stack padding={1}>
          <Typography variant="subtitle2" color={'text.primary'} marginBottom={1}>
            {selectedArtist ? 'Viewing artist profile' : 'Select an artist profile'}
          </Typography>
          {selectedArtist && (
            <Stack direction="row" alignItems="center">
              <img
                src={selectedArtistImage || UserIcon}
                alt="user"
                width={'28'}
                height={'28'}
                style={{ borderRadius: '6px', marginRight: '8px' }}
              />
              <Typography variant="subtitle2" color={'text.secondary'}>
                {selectedArtist?.artist_name}
              </Typography>
              <AppIcon
                icon={'arrowdown'}
                sx={{
                  marginLeft: 'auto',
                  fontSize: '24px',
                  color: (theme) => theme.palette.text.secondary,
                }}
              />
            </Stack>
          )}
        </Stack>
      </Stack>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        color="info"
        sx={{
          marginTop: '8px',

          '& .MuiPaper-root': {
            background: 'white',
            minWidth: '240px',
            maxHeight: '300px',
            overflowY: 'auto',
            border: '1px solid #2C2D2D33',
            borderRadius: '20px',
          },
        }}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {artists.map((artist, index) => {
          const artistImageData = artistImages?.find(
            (img) => img.rtsvrf_artist_id === artist?.rtsvrf_artist_id
          );

          const artistImage = artistImageData
            ? artistImageData[artistImageData.artist_picture_preference]
            : null;
          return (
            <MenuItem
              key={index}
              onClick={() => handleArtistSelection(artist, artistImage)}
              sx={{
                borderBottom: '0.5px solid #EAEAEA',
              }}
            >
              <Stack direction="row" alignItems="center" paddingY={1} paddingX={0.5}>
                <img
                  src={artistImage || UserIcon}
                  alt="user"
                  width={'28'}
                  height={'28'}
                  style={{ borderRadius: '6px', marginRight: '8px' }}
                />
                <Stack>
                  <Typography variant="subtitle2" color={'black'}>
                    {artist?.artist_name}
                  </Typography>
                  <Typography variant="h5" color={'text.primary'}>
                    Review profile
                  </Typography>
                </Stack>
              </Stack>
              {selectedArtist?.rtsvrf_artist_id === artist?.rtsvrf_artist_id && (
                <AppIcon
                  icon={'check'}
                  sx={{
                    marginLeft: 'auto',
                    fontSize: '24px',
                    color: (theme) => theme.palette.error.main,
                  }}
                />
              )}
            </MenuItem>
          );
        })}
      </Menu>
    </Stack>
  );
};

export default ArtistDelegation;
