import { useEffect } from 'react';
import { useAppStore } from '../store';
import { useLocation } from 'react-router-dom';
import PublicRoutes from './PublicRoutes';
import PrivateRoutes from './PrivateRoutes';
import suprsend from '@suprsend/web-sdk';
import { api } from '../api';
import { useIsAuthenticated } from '../hooks/auth';
import { SET_USER_IP_DATA } from '../store/Actions';

suprsend.init(
  process.env.REACT_APP_SUPRSEND_WORKSPACE_KEY,
  process.env.REACT_APP_SUPRSEND_WORKSPACE_SECRET
);
/**
 * Renders Application routes depending on Logged or Anonymous users
 * @component AppRoutes
 */
const AppRoutes = () => {
  const [state, dispatch] = useAppStore();
  const location = useLocation();
  // const isAuthenticated = state.isAuthenticated; // Variant 1
  const isAuthenticated = useIsAuthenticated(); // Variant 2

  useEffect(() => {
    api.GA.initGA();
  }, []);

  useEffect(() => {
    api.GA.sendPageData('pageview', location.pathname, location.pathname);
  }, [location]);

  useEffect(() => {
    const getIp = async () => {
      const data = await api.auth.ip();
      const userIpData = {
        ip_address: data.ip,
        user_agent: navigator.userAgent,
      };
      dispatch({ type: SET_USER_IP_DATA, userIpData });
    };
    !state.userIpData && getIp();
  }, [state, dispatch]);

  useEffect(() => {
    const fetchUserData = async () => {
      const result = await api.db.editor.profile.getUserAuth();
      if (result) {
        await api.auth.login.setAuthenticatedUser(result);
      }
    };
    !state.currentUser && fetchUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  // Re-login or logout the user if needed
  // useEffect(() => {
  //   // Check isn't token expired?
  //   const isLogged = isUserStillLoggedIn();

  //   if (isAuthenticated && !isLogged) {
  //     // Token was expired, logout immediately!
  //     log.warn('Token was expired, logout immediately!');
  //     api?.auth?.logout();
  //     // dispatch({ type: 'LOG_OUT' }); // Not needed due to reloading App in api.auth.logout()
  //     return; // Thats all for now, the App will be completely re-rendered soon
  //   }

  //   if (isLogged && !isAuthenticated) {
  //     // Valid token is present but we are not logged in somehow, lets fix it
  //     console.warn('Token found, lets try to auto login');
  //     api?.auth?.refresh().then(() => {
  //       dispatch({ type: LOG_IN }); // Update global store only if token refresh was successful.
  //     });
  //   }
  // }, [isAuthenticated, dispatch]); // Effect for every isAuthenticated change actually

  return isAuthenticated ? <PrivateRoutes /> : <PublicRoutes />;
};

export default AppRoutes;
