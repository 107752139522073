import moment from 'moment';
import { supabase } from '../SupabaseClient';

export const getArtistFollowersCount = async (artistSpotifyId, artistAppleId) => {
  if (!artistSpotifyId) return;

  let results = await supabase
    .from('tbl_staged_fan_user_profile')
    .select('*', { count: 'exact' })
    .or(
      `spotify_followed_artists_id.cs.{${artistSpotifyId}},apple_followed_artists_id.cs.{${artistAppleId}}`
    );
  return results.count;
};

export const getArtistTop3FollowersCount = async (artistId) => {
  if (!artistId) return;

  let results = await supabase
    .from('tbl_staged_fan_user_profile')
    .select('*', { count: 'exact' })
    .or(
      `favourite_1.eq.${artistId},favourite_2.eq.${artistId},favourite_3.eq.${artistId},favourite_4.eq.${artistId},favourite_5.eq.${artistId}`
    );

  return { count: results.count ?? 0, data: results.data ?? [] };
};

export const getArtistTop3FollowersCountByRange = async (artistId, fromValue, toValue) => {
  if (!artistId) return;

  let results = await supabase
    .from('tbl_staged_fan_user_profile')
    .select('*', { count: 'exact' })
    .or(
      `and(favourite_1.eq.${artistId},favourite_1_timestamp.gte.${fromValue.toISOString()},favourite_1_timestamp.lt.${toValue.toISOString()}),and(favourite_2.eq.${artistId},favourite_2_timestamp.gte.${fromValue.toISOString()},favourite_2_timestamp.lt.${toValue.toISOString()}),and(favourite_3.eq.${artistId},favourite_3_timestamp.gte.${fromValue.toISOString()},favourite_3_timestamp.lt.${toValue.toISOString()}),and(favourite_4.eq.${artistId},favourite_4_timestamp.gte.${fromValue.toISOString()},favourite_4_timestamp.lt.${toValue.toISOString()}),and(favourite_5.eq.${artistId},favourite_5_timestamp.gte.${fromValue.toISOString()},favourite_5_timestamp.lt.${toValue.toISOString()})`
    );

  return { count: results.count ?? 0, data: results.data ?? [] };
};

export const getArtistTop1FollowersCount = async (artistId) => {
  if (!artistId) return;

  let results = await supabase
    .from('tbl_staged_fan_user_profile')
    .select('*', { count: 'exact' })
    .eq('favourite_1', artistId);

  return { count: results.count ?? 0, data: results.data ?? [] };
};
export const getArtistTop1FollowersCountByRange = async (artistId, fromValue, toValue) => {
  if (!artistId) return;

  let results = await supabase
    .from('tbl_staged_fan_user_profile')
    .select('*', { count: 'exact' })
    .or(`favourite_1.eq.${artistId},favourite_2.eq.${artistId}`)
    .filter('favourite_1_timestamp', 'gte', fromValue.toISOString())
    .filter('favourite_1_timestamp', 'lt', toValue.toISOString());

  return { count: results.count ?? 0, data: results.data ?? [] };
};

export const getFansCountBySegment = async (artistId, artistSpotifyId, artistAppleId, type) => {
  if (!artistId) return;

  let results = await supabase
    .from('tbl_staged_fan_user_profile')
    .select('*', { count: 'exact' })
    .eq('rtsvrf_fan_segment', type)
    .or(
      `favourite_1.eq.${artistId},favourite_2.eq.${artistId},favourite_3.eq.${artistId},favourite_4.eq.${artistId},favourite_5.eq.${artistId},spotify_followed_artists_id.cs.{${artistSpotifyId}},apple_followed_artists_id.cs.{${artistAppleId}}`
    );
  console.log(results, 'results');
  return { count: results.count ?? 0, data: results.data ?? [] };
};

export const getNewFansListByDateRange = async (
  artistId,
  artistSpotifyId,
  artistAppleId,
  fromValue,
  toValue
) => {
  if (!artistId) return;

  let results = await supabase
    .from('tbl_staged_fan_user_profile')
    .select('*')
    .or(
      `favourite_1.eq.${artistId},favourite_2.eq.${artistId},favourite_3.eq.${artistId},favourite_4.eq.${artistId},favourite_5.eq.${artistId},spotify_followed_artists_id.cs.{${artistSpotifyId}},apple_followed_artists_id.cs.{${artistAppleId}}`
    )
    .filter('created_at', 'gte', fromValue.toISOString())
    .filter('created_at', 'lt', toValue.toISOString());
  return results.data ?? [];
};

export const getFansAddedByDateRange = async (
  artistId,
  artistSpotifyId,
  artistAppleId,
  fromDate,
  toDate
) => {
  if (!artistId || !fromDate || !toDate) return;
  const fanCounts = {};

  // Convert fromDate and toDate to Moment.js objects
  const fromMoment = moment(fromDate).startOf('day');
  const toMoment = moment(toDate).endOf('day');

  // Loop through each day in the date range
  for (let date = moment(fromMoment); date <= toMoment; date.add(1, 'day')) {
    // Get the formatted date string
    const dateString = date.format('YYYY-MM-DD');

    // Fetch fan count for the current date
    let results = await supabase
      .from('tbl_staged_fan_user_profile')
      .select('*', { count: 'exact' })
      .or(
        `favourite_1.eq.${artistId},favourite_2.eq.${artistId},favourite_3.eq.${artistId},favourite_4.eq.${artistId},favourite_5.eq.${artistId},spotify_followed_artists_id.cs.{${artistSpotifyId}},apple_followed_artists_id.cs.{${artistAppleId}}`
      )
      .filter('created_at', 'gte', date.startOf('day').toISOString())
      .filter('created_at', 'lt', date.endOf('day').toISOString());

    // Store the fan count for the current date
    fanCounts[dateString] = results.count;
  }

  return fanCounts;
};
