import { SET_CURRENT_USER, SET_CURRENT_USER_MAPPING } from '../../../store/Actions';
import { getGlobalDispatch, getGlobalState } from '../../../store/AppStore';
import { supabase } from '../SupabaseClient';

export const getArtistEditorMappingsById = async (id) => {
  if (!id) return;
  const results = await supabase
    .from('tbl_staged_artist_editor_profile')
    .select()
    .eq('supabase_user_reference', id)
    .single();
  return results;
};

export const updateArtistEditorMappingsBySupabaseId = async (id, data) => {
  console.log(id, data, 'log in');
  if (!id || !data) return;
  const results = await supabase
    .from('tbl_staged_artist_editor_profile')
    .update(data)
    .eq('supabase_user_reference', id);
  const dispatch = getGlobalDispatch();
  const state = getGlobalState();
  if (state.currentUserMapping)
    dispatch({ type: SET_CURRENT_USER_MAPPING, payload: { ...state.currentUserMapping, ...data } });

  return results;
};

export const getUserSubscriberKey = async (user) => {
  if (!user) return;
  const options = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(
    `${process.env.REACT_APP_INTERNAL_SERVER}/subscriber-key/${user.id}`,
    options
  );
  const result = await response.json();

  if (result?.subscriberKey) {
    const updatedUserMetadata = {
      ...user.user_metadata,
      suprsend_subscriber_id: result?.subscriberKey,
    };
    await updateArtistEditorProfile(updatedUserMetadata);
  }
  console.log(result);
  return result?.subscriberKey ?? null;
};

export const updateArtistEditorProfile = async (userData) => {
  const result = await supabase.auth.updateUser({
    data: { ...userData },
  });
  const dispatch = getGlobalDispatch();
  const state = getGlobalState();
  console.log(state.currentUser, 'state.currentUser');
  if (state.currentUser)
    dispatch({
      type: SET_CURRENT_USER,
      payload: {
        ...state.currentUser,
        user_metadata: { ...(state.currentUser?.user_metadata ?? {}), ...userData },
      },
    });

  return result;
};

export const updateArtistEditableFields = async (artistId, data) => {
  if (!artistId || !data) return;
  const results = await supabase.from('tbl_locked_artist_editable_fields').upsert({
    ...data,
    updated_at: new Date(),
  });
  return results;
};

export const getUserAuth = async () => {
  const user = await supabase.auth.getUser();
  return user?.data?.user;
};
