import { useCallback, useState } from 'react';
import { Stack } from '@mui/material/';
import { useAppStore } from '../store/AppStore';
import { useLocation } from 'react-router-dom';
import { ErrorBoundary } from '../components';
import { useOnMobile } from '../hooks/layout';
import {
  SIDEBAR_DESKTOP_ANCHOR,
  SIDEBAR_WIDTH,
  TOPBAR_DESKTOP_HEIGHT,
  TOPBAR_MOBILE_HEIGHT,
} from './config';
import TopBar from './TopBar';
import LogoIcon from '../assets/logo/avTagLogo.png';
import TagIcon from '../assets/logo/tag.png';
import SideBar from './SideBar';

// TODO: change to your app name or other word
const TITLE_PUBLIC = 'ARTISTVERIFIED'; // Title for pages without/before authentication

/**
 * SideBar navigation items with links
 */
const SIDEBAR_ITEMS = [
  {
    title: 'Log In',
    path: '/',
    icon: 'login',
  },
];

/**
 * BottomBar navigation items with links
 */
const BOTTOMBAR_ITEMS = [
  {
    title: 'Log In',
    path: '/auth/login',
    icon: 'login',
  },
  {
    title: 'Sign Up',
    path: '/auth/signup',
    icon: 'signup',
  },
  {
    title: 'About',
    path: '/about',
    icon: 'info',
  },
];

/**
 * Renders "Public Layout" composition
 * @component PublicLayout
 */
const PublicLayout = ({ children }) => {
  const onMobile = useOnMobile();
  const [sideBarVisible, setSideBarVisible] = useState(false);
  const location = useLocation();
  const [state] = useAppStore();

  // Variant 1 - Sidebar is static on desktop and is a drawer on mobile
  // const sidebarOpen = onMobile ? sideBarVisible : true;
  // const sidebarVariant = onMobile ? 'temporary' : 'persistent';

  // Variant 2 - Sidebar is drawer on mobile and desktop
  const sidebarOpen = sideBarVisible;
  const sidebarVariant = 'temporary';

  const title = TITLE_PUBLIC;
  document.title = title; // Also Update Tab Title

  const onSideBarOpen = useCallback(() => {
    if (!sideBarVisible) setSideBarVisible(true); // Don't re-render Layout when SideBar is already open
  }, [sideBarVisible]);

  const onSideBarClose = useCallback(() => {
    if (sideBarVisible) setSideBarVisible(false); // Don't re-render Layout when SideBar is already closed
  }, [sideBarVisible]);

  return (
    <Stack
      direction="column"
      sx={{
        minHeight: '100vh', // Full screen height
        paddingTop: onMobile ? TOPBAR_MOBILE_HEIGHT : TOPBAR_DESKTOP_HEIGHT,
        paddingLeft: sidebarOpen && SIDEBAR_DESKTOP_ANCHOR.includes('left') ? SIDEBAR_WIDTH : 0,
        paddingRight: sidebarOpen && SIDEBAR_DESKTOP_ANCHOR.includes('right') ? SIDEBAR_WIDTH : 0,
        background: (theme) => theme.palette.info.main,
      }}
    >
      <Stack component="header">
        <TopBar
          startNode={
            <img src={TagIcon} alt="tag" width={'200'} height={'55'} onClick={onSideBarOpen} />
          }
        />
      </Stack>

      <Stack
        component="main"
        sx={{
          flexGrow: 1, // Takes all possible space
          paddingLeft: '20px',
          paddingRight: 0,
          paddingTop: 0,
          marginTop: 0,

          background: (theme) => theme.palette.background.default,
        }}
      >
        {children}
      </Stack>
    </Stack>
  );
};

export default PublicLayout;
