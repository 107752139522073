import { api } from '..';
import { LOG_IN, LOG_OUT, SET_CURRENT_USER_MAPPING } from '../../store/Actions';
import suprsend from '@suprsend/web-sdk';
import { getGlobalDispatch, getGlobalState } from '../../store/AppStore';
import { supabase } from '../supabase/SupabaseClient';

export const logoutWithRedirect = async () => {
  const state = getGlobalState();
  const dispatch = getGlobalDispatch();
  const userIpData = state.userIpData;
  const user = state.currentUser;

  await api.GA.sendEventData('LogOut', 'Click', 'LogOut');
  // suprsend.reset();
  localStorage.clear();
  await api.log.insert('Log Out', {
    ...userIpData,
    email: user.email,
    timestamp: new Date(),
    type: 'Editor',
  });
  dispatch({ type: LOG_OUT });

  const { error } = await supabase.auth.signOut();
  if (error) {
    return { error: true, message: error.message };
  } else {
    return { success: true, message: 'Logged out successfully' };
  }
};

export const sendOtp = async (email) => {
  const state = getGlobalState();
  const userIpData = state.userIpData ?? {};

  await api.GA.sendEventData('Sign In', 'Click', 'Sign In');

  const { data, error } = await supabase.auth.signInWithOtp({
    email: email,
    options: {
      emailRedirectTo: `${process.env.REACT_APP_REDIRECT_URL}`,
      shouldCreateUser: false,
    },
  });
  if (error) {
    return { error: true, message: error.message };
  } else {
    await api.log.insert('Token Requested', {
      ...userIpData,
      email: email,
      timestamp: new Date(),
      type: 'Editor',
    });
    return { success: true, message: 'OTP sent to email!' };
  }
};

export const login = async (email, otp) => {
  await api.GA.sendEventData('Verify OTP', 'click', 'Verify OTP');
  const { data, error } = await supabase.auth.verifyOtp({
    email,
    token: otp,
    type: 'email',
  });
  if (error) {
    return { error: true, message: error.message };
  } else if (data) {
    const result = await setAuthenticatedUser(data.user);
    return result;
  }
};

export const setAuthenticatedUser = async (user) => {
  const state = getGlobalState();
  const userIpData = state.userIpData ?? {};

  const isValidUser = await api.db.editor.profile.getArtistEditorMappingsById(user.id);
  const validUserData = isValidUser?.data;
  console.log(validUserData, 'validUserData');
  if (validUserData && validUserData.editor_account_active) {
    await api.log.insert('Log In', {
      ...userIpData,
      email: user.email,
      timestamp: new Date(),
      type: 'Editor',
    });
    const updateData = {
      login_timestamp: new Date(),
    };
    if (!user.user_metadata?.fan_coexistence_status) {
      const fanProfile = await api.db.user.profile.getFanProfile(user.email);
      if (fanProfile) {
        updateData.fan_coexistence_status = fanProfile?.rtsvrf_fan_uuid ?? null;
      }
    }
    await api.db.editor.profile.updateArtistEditorMappingsBySupabaseId(user.id, updateData);

    // suprsend.identify(user.id);
    // suprsend.user.set('name', user.user_metadata?.name);
    // suprsend.user.add_email(user.email);
    if (!user.user_metadata?.suprsend_subscriber_id) {
      await api.db.editor.profile.getUserSubscriberKey(user);
    }

    const dispatch = getGlobalDispatch();
    dispatch({ type: LOG_IN, payload: user });
    dispatch({ type: SET_CURRENT_USER_MAPPING, payload: validUserData });
    return { success: true, message: 'Logged in successfully' };
  } else if (validUserData && !validUserData.editor_account_active) {
    await api.log.insert(`Attempt to log into deactivated account - ${user.email}`, {
      ...userIpData,
      email: user.email,
      timestamp: new Date(),
      details: `User with email -${user.email} and ID - ${user.id} tried to log into deactivated account`,
      type: 'Editor',
    });
    await supabase.auth.signOut();
    localStorage.clear();
    return { error: true, message: 'Access denied! Your account has been deactivated' };
  } else {
    await api.log.insert('Invalid User Log In', {
      ...userIpData,
      email: user.email,
      timestamp: new Date(),
      details: `User with email -${user.email} and ID - ${user.id} tried to access Artist Admin App without permission`,
      type: 'Editor',
    });
    await supabase.auth.signOut();
    localStorage.clear();
    return { error: true, message: 'You do not have permission to access this app' };
  }
};
