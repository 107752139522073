import { SHARED_CONTROL_PROPS } from '../../utils/form';
import { TextField } from '@mui/material';
import { INPUT_DEFAULT_SX_VALUES } from '../../utils/style';

const AppTextField = ({
  children,
  label,
  fieldLabel,
  name,
  required,
  value,
  borderRadius,
  onFieldChange = () => {},
  fieldHasError = () => false,
  fieldGetError = () => '',
  sx: propSx,
  ...restOfProps
}) => {
  return (
    <>
      {fieldLabel && <label htmlFor={name}>{fieldLabel}</label>}
      <TextField
        {...SHARED_CONTROL_PROPS}
        required={required}
        label={label}
        name={name}
        value={value}
        error={fieldHasError(name)}
        helperText={fieldGetError(name) || ''}
        onChange={onFieldChange}
        onClick={(e) => {
          console.log(e.target.value);
        }}
        inputProps={{ style: { height: '20px' } }}
        sx={{
          ...INPUT_DEFAULT_SX_VALUES,
          ...propSx,
        }}
        {...restOfProps}
      />
    </>
  );
};

export default AppTextField;
