import { SET_SELECTED_ARTIST } from '../../../store/Actions';
import { getGlobalDispatch, getGlobalState } from '../../../store/AppStore';
import { supabase } from '../SupabaseClient';

export const updateArtistProfile = async (id, data) => {
  if (!data || !id) return;
  let results = await supabase
    .from('tbl_locked_artist_ids_stub_v2')
    .update({
      ...data,
    })
    .eq('rtsvrf_artist_id', id);
  const dispatch = getGlobalDispatch();
  const state = getGlobalState();
  if (state.selectedArtist)
    dispatch({ type: SET_SELECTED_ARTIST, payload: { ...state.selectedArtist, ...data } });

  return results;
};

export const updateArtistEditableFields = async (artistId, data) => {
  if (!artistId || !data) return;
  const results = await supabase.from('tbl_locked_artist_editable_fields').upsert({
    ...data,
    rtsvrf_artist_id: artistId,
    updated_at: new Date(),
  });
  return results;
};

export const updateArtistPreferences = async (data) => {
  const results = await supabase.from('tbl_staged_artist_profile_preferences').upsert({
    ...data,
  });
  return results;
};

export const updateArtistProfile2Preferences = async (data) => {
  if (!data) return;
  let results = await supabase.from('tbl_locked_artist_profile_v2_preferences').upsert({
    ...data,
    updated_at: new Date(),
  });
  return results;
};

export const updateArtistImagePreference = async (artistId, data) => {
  if (!data || !artistId) return;
  const results = await supabase
    .from('tbl_locked_artist_pics')
    .update({
      ...data,
    })
    .eq('rtsvrf_artist_id', artistId);
  return results;
};
