import { supabase } from '../SupabaseClient';

export const getArtistsByLabel = async (label) => {
  if (!label) return;
  const results = await supabase
    .from('tbl_locked_artist_ids_stub_v2')
    .select()
    .filter('artist_label_connection', 'cs', `{${label}}`);
  return results.data ?? [];
};

export const getDelegatedArtists = async (label, artistIds) => {
  if (!label && !artistIds) return;
  const results = await supabase
    .from('tbl_locked_artist_ids_stub_v2')
    .select()
    .or(`rtsvrf_artist_id.in.(${artistIds.join(',')}), artist_label_connection.cs.{${label}}`)
    .order('artist_name', { ascending: true });
  return results.data;
};

export const getArtistCustomMessageList = async (artistId, editorId) => {
  const results = await supabase
    .from('tbl_locked_editor_custom_list')
    .select()
    .eq('artist_id', artistId)
    .eq('editor_id', editorId);
  return results.data ?? [];
};
