import PropTypes from 'prop-types';
import { AppBar, Toolbar, Typography } from '@mui/material';
import { dividerStyle } from '../../utils/style';
/**
 * Renders TopBar composition
 * @component TopBar
 */
const TopBar = ({ endNode, startNode, title = '', toolbarSx = {}, ...restOfProps }) => {
  return (
    <AppBar
      component="div"
      sx={{
        background: (theme) => theme.palette.info.main,
        padding: '10px',
        boxShadow: 'none',
        ...dividerStyle,
      }}
      {...restOfProps}
    >
      <Toolbar disableGutters sx={{ paddingX: 1, ...toolbarSx }}>
        {startNode}

        {title && (
          <Typography
            variant="h4"
            sx={{
              marginX: 1,
              textAlign: 'left',
              whiteSpace: 'nowrap',
            }}
          >
            {title}
          </Typography>
        )}

        {endNode}
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  endNode: PropTypes.element,
  startNode: PropTypes.element,
  title: PropTypes.string,
};

export default TopBar;
