import { supabase } from '../SupabaseClient';
import { api } from '../..';

export const getCountryNames = async () => {
  const results = await supabase.from('tbl_locked_countries').select();
  return results?.data;
};

export const getCountryPhoneNumberCodes = async () => {
  const results = await supabase.from('tbl_locked_phone_codes').select();
  return results?.data;
};

export const uploadImageToBucket = async (bucket, selectedImage, arrayBuffer) => {
  if (!bucket) return { error: true, message: 'Something went wrong!' };
  const time = new Date().getTime();
  const { data, error } = await supabase.storage
    .from(bucket)
    .upload(`public/${selectedImage.name}_${time}`, arrayBuffer, {
      contentType: selectedImage.type,
    });

  if (error) {
    console.error('Error uploading image:', error);
    return { error: true, message: error };
  } else {
    console.log('Image uploaded successfully:', data);
    const res = supabase.storage.from(bucket).getPublicUrl(data.path);
    return (
      res?.data?.publicUrl ?? {
        error: true,
        message: 'Failed to upload an image',
      }
    );
  }
};

export const getLabelsByPage = async (currentPage = 1, limit = 5, searchString = '') => {
  let query = supabase.from('tbl_locked_labels').select('*', { count: 'exact' });
  if (searchString) {
    query = query.ilike('rtsvrf_label_name', `%${searchString}%`);
  }
  query = query.range((currentPage - 1) * limit, (currentPage - 1) * limit + (limit - 1));
  let results = await query;
  return results;
};

export const getAllLabels = async (searchString = '') => {
  let query = supabase.from('tbl_locked_labels').select('*', { count: 'exact' });
  if (searchString) {
    query = query.ilike('rtsvrf_label_name', `%${searchString}%`);
  }
  let results = await query;
  return results.count;
};

export const getLabelsByCode = async (codes) => {
  if (!codes) return;
  const results = await supabase
    .from('tbl_locked_labels')
    .select()
    .filter('artist_label_connection', 'cs', `{${codes.join(',')}}`);
  return results.data ?? [];
};
