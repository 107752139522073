import React from 'react';
/**
 * Note: Don't import/export all Views directly, use lazy loading!
 */
import { withSuspense } from '../components';
import NotFoundView from './NotFoundView';
import NotImplementedView from './NotImplementedView';

/**
 * Views/Pages with Lazy Loading
 */
const ArtistProfilePage = withSuspense(React.lazy(() => import('./ArtistProfile')));
const ActivityPage = withSuspense(React.lazy(() => import('./Activity')));
const PartneringView = withSuspense(React.lazy(() => import('./Partnering')));
const ManageProfilePage = withSuspense(React.lazy(() => import('./ManageProfile')));
const ManageDataPage = withSuspense(React.lazy(() => import('./ManageData')));
const FanStatisticsPage = withSuspense(React.lazy(() => import('./FanStatistics')));
const FanDataPage = withSuspense(React.lazy(() => import('./FanData')));
const EditorProfilePage = withSuspense(React.lazy(() => import('./EditorProfile')));
const WelcomePage = withSuspense(React.lazy(() => import('./Welcome')));
const CreatePostPage = withSuspense(React.lazy(() => import('./CreatePost')));
const MessagesPage = withSuspense(React.lazy(() => import('./Messages')));
const AboutView = withSuspense(React.lazy(() => import('./About')));
const ToolsView = () => <NotImplementedView name="Following" />; // Sample of non-implemented View
const UserView = () => <NotImplementedView name="Messages" />; // Sample of non-implemented View

export {
  NotFoundView,
  AboutView,
  WelcomePage,
  EditorProfilePage,
  PartneringView,
  CreatePostPage,
  ArtistProfilePage,
  ManageProfilePage,
  ManageDataPage,
  MessagesPage,
  ActivityPage,
  FanStatisticsPage,
  FanDataPage,
  UserView,
  ToolsView,
};
