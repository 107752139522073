import PropTypes from 'prop-types';
import { SvgIcon } from '@mui/material';
// SVG assets
import LogoIcon from '../../assets/logo/avmainlogo.png';
// Material Icons
import DefaultIcon from '@mui/icons-material/MoreHoriz';
import SettingsIcon from '@mui/icons-material/Settings';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import NightIcon from '@mui/icons-material/Brightness3';
import DayIcon from '@mui/icons-material/Brightness5';
import SearchIcon from '@mui/icons-material/Search';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import HomeIcon from '@mui/icons-material/Home';
import EmailIcon from '@mui/icons-material/Email';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonIcon from '@mui/icons-material/Person';
import TurnedInIcon from '@mui/icons-material/TurnedIn';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import NotificationsIcon from '@mui/icons-material/NotificationsOutlined';
import FilterListIcon from '@mui/icons-material/FilterList';
import CheckIcon from '@mui/icons-material/Check';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import BuildIcon from '@mui/icons-material/Build';
import TurnedInNotIcon from '@mui/icons-material/TurnedInNot';
import AccountCircle from '@mui/icons-material/AccountCircle';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import AppleIcon from '@mui/icons-material/Apple';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import InsertLinkSharpIcon from '@mui/icons-material/InsertLinkSharp';
import QueryStatsSharpIcon from '@mui/icons-material/QueryStatsSharp';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import TroubleshootOutlinedIcon from '@mui/icons-material/TroubleshootOutlined';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ClearIcon from '@mui/icons-material/Clear';
/**
 * How to use:
 * 1. Import all required MUI or other SVG icons into this file.
 * 2. Add icons with "unique lowercase names" into ICONS object.
 * 3. Use icons everywhere in the App by their names in <AppIcon name="xxx" /> component
 * Important: properties of ICONS object MUST be lowercase!
 * Note: You can use camelCase or UPPERCASE in the <AppIcon name="someIconByName" /> component
 */
const ICONS = {
  default: DefaultIcon,
  close: CloseIcon,
  menu: MenuIcon,
  settings: SettingsIcon,
  visibilityon: VisibilityIcon,
  visibilityoff: VisibilityOffIcon,
  notifications: NotificationsIcon,
  search: SearchIcon,
  more: MoreHorizIcon,
  filter: FilterListIcon,
  sort: SwapVertIcon,
  night: NightIcon,
  day: DayIcon,
  home: HomeIcon,
  info: InfoOutlinedIcon,
  signup: PersonAddIcon,
  login: PersonIcon,
  logout: ExitToAppIcon,
  smile: InsertEmoticonIcon,
  tools: BuildIcon,
  message: EmailIcon,
  account: AccountCircle,
  saved: TurnedInIcon,
  save: TurnedInNotIcon,
  favourite: FavoriteIcon,
  back: ArrowBackIosIcon,
  next: ArrowForwardIosIcon,
  apple: AppleIcon,
  accountcircle: AccountCircleIcon,
  arrowdown: ExpandMoreIcon,
  check: CheckIcon,
  cross: ClearIcon,
  manage: BorderColorIcon,
  link: InsertLinkSharpIcon,
  analytics: QueryStatsSharpIcon,
  list: FactCheckOutlinedIcon,
  activity: TroubleshootOutlinedIcon,
  up: KeyboardArrowUpIcon,
  down: KeyboardArrowDownIcon,
  circle: PanoramaFishEyeIcon,
};

/**
 * Renders SVG icon by given Icon name
 * @component AppIcon
 * @param {string} [icon] - name of the Icon to render
 * @param {string} [name] - name of the Icon to render, overrides .icon property
 */
const AppIcon = ({ icon, name, ...restOfProps }) => {
  const iconName = (name || icon || 'default').trim().toLowerCase();
  const ComponentToRender = ICONS[iconName] || DefaultIcon;
  return <ComponentToRender {...restOfProps} />;
};

AppIcon.propTypes = {
  icon: PropTypes.string,
  name: PropTypes.string,
};

export default AppIcon;
