import { SHARED_CONTROL_PROPS } from '../../utils/form';
import { Select, MenuItem } from '@mui/material';

const AppSelectField = ({
  children,
  label,
  fieldLabel,
  name,
  options,
  required,
  value,
  multiple,
  borderRadius,
  onFieldChange = () => {},
  fieldHasError = () => false,
  fieldGetError = () => '',
  sx: propSx,
  ...restOfProps
}) => {
  const DEFAULT_SX_VALUES = {
    marginTop: '4px',
    borderRadius: borderRadius ?? '20px',
    border: '1px solid gray',
    borderWidth: '1px',
    color: 'white',
    backgroundColor: (theme) => theme.palette.background.paper,
    padding: '0px',
    '& svg': {
      display: 'none',
    },
    '&.Mui-focused fieldset': {
      borderWidth: '1px !important',
    },
  };

  return (
    <>
      {fieldLabel && <label htmlFor={name}>{fieldLabel}</label>}
      <Select
        {...SHARED_CONTROL_PROPS}
        required={required}
        label={label}
        name={name}
        multiple={multiple ?? false}
        value={value ?? null}
        error={fieldHasError(name)}
        helperText={fieldGetError(name) || ''}
        onChange={onFieldChange}
        inputProps={{ style: { height: '20px' } }}
        sx={{
          ...DEFAULT_SX_VALUES,
          ...propSx,
        }}
        {...restOfProps}
      >
        {options?.length &&
          options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
      </Select>
    </>
  );
};

export default AppSelectField;
