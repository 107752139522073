import { useState, useCallback, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import UserIcon from '../assets/icons/user.png';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { AppIconButton, ErrorBoundary } from '../components';
import { useOnMobile } from '../hooks/layout';
import LogoIcon from '../assets/logo/avTagLogo.png';
import TagIcon from '../assets/logo/tag.png';
import {
  BOTTOMBAR_DESKTOP_VISIBLE,
  SIDEBAR_DESKTOP_ANCHOR,
  SIDEBAR_MOBILE_ANCHOR,
  SIDEBAR_WIDTH,
  TOPBAR_DESKTOP_HEIGHT,
  TOPBAR_MOBILE_HEIGHT,
} from './config';
import TopBar from './TopBar/TopBar';
import SideBar from './SideBar/SideBar';
import BottomBar from './BottomBar';
import { BOTTOMBAR_ITEMS } from '../utils/constants';
import { useAppStore } from '../store';
import api from '../api';

// TODO: change to your app name or other word
const TITLE_PRIVATE = 'ARTISTVERIFIED'; // Title for pages after authentication

/**
 * SideBar navigation items with links
 */

/**
 * Renders "Private Layout" composition
 * @component PrivateLayout
 */
const PrivateLayout = ({ children }) => {
  const navigation = useNavigate();
  const location = useLocation();
  const [sideBarVisible, setSideBarVisible] = useState(true);
  const onMobile = useOnMobile();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const buttonRef = useRef(null);
  const [state, dispatch] = useAppStore();

  // Set spotify token
  console.log(location);
  // Variant 1 - Sidebar is static on desktop and is a drawer on mobile
  const sidebarOpen = onMobile ? sideBarVisible : true;
  const sidebarVariant = onMobile ? 'temporary' : 'persistent';
  const topBarVisible = state.showTopBarPageList?.includes(location.pathname) ? true : false;
  const profileIconVisible = state.hideProfileIcon?.includes(location.pathname) ? false : true;

  const title = TITLE_PRIVATE;
  document.title = title; // Also Update Tab Title

  useEffect(() => {
    // Function to close the menu when clicking outside
    const handleClickOutside = (event) => {
      if (buttonRef.current && !buttonRef.current.contains(event.target)) {
        setAnchorEl(null);
      }
    };

    // Add event listener when the menu is open
    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      // Remove event listener when the menu is closed
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      // Clean up the event listener
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    const result = await api.auth.login.logoutWithRedirect();
    if (result.success) {
      navigation('/');
    }
  };

  return (
    <Stack
      direction="column"
      sx={{
        minHeight: '100vh', // Full screen height
        paddingTop: topBarVisible
          ? onMobile
            ? TOPBAR_MOBILE_HEIGHT + 20
            : TOPBAR_DESKTOP_HEIGHT + 20
          : 0,
        paddingLeft: sidebarOpen && SIDEBAR_DESKTOP_ANCHOR.includes('left') ? SIDEBAR_WIDTH : 0,
        paddingRight: sidebarOpen && SIDEBAR_DESKTOP_ANCHOR.includes('right') ? SIDEBAR_WIDTH : 0,
        background: (theme) => theme.palette.info.main,
      }}
    >
      <Stack component="header">
        <TopBar
          startNode={<img src={TagIcon} alt="tag" width={'200'} height={'55'} />}
          endNode={
            profileIconVisible ? (
              <>
                <Stack
                  onClick={handleClick}
                  marginLeft={'auto'}
                  direction={'row'}
                  alignItems={'center'}
                  ref={buttonRef}
                >
                  <Typography variant="subtitle2" color={'text.secondary'} marginRight={1}>
                    {state.currentUser?.user_metadata?.name ?? 'Admin'}
                  </Typography>

                  <img
                    src={state.currentUser?.user_metadata?.profile_picture_link ?? UserIcon}
                    style={{ borderRadius: '16px', marginLeft: 'auto' }}
                    alt="logo"
                    onClick={() => navigation('/profile')}
                    width={'48'}
                    height={'48'}
                  />
                </Stack>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  color="info"
                  sx={{
                    marginTop: '8px',

                    '& .MuiPaper-root': {
                      background: 'white',
                      minWidth: '240px',
                      maxHeight: '300px',
                      overflowY: 'auto',
                      border: '1px solid #2C2D2D33',
                      borderRadius: '20px',
                    },
                  }}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem
                    key={'0'}
                    onClick={() => {
                      navigation('/profile');
                      handleClose();
                    }}
                  >
                    <Stack direction="row" alignItems="center" paddingY={1} paddingX={0.5}>
                      <Typography variant="subtitle2" color={'black'}>
                        Account Details
                      </Typography>
                    </Stack>
                  </MenuItem>
                  <MenuItem
                    key={'1'}
                    onClick={() => {
                      handleLogout();
                      handleClose();
                    }}
                    sx={{
                      borderBottom: '0.5px solid #EAEAEA',
                    }}
                  >
                    <Stack direction="row" alignItems="center" paddingY={1} paddingX={0.5}>
                      <Typography variant="subtitle2" color={'black'}>
                        Log out
                      </Typography>
                    </Stack>
                  </MenuItem>
                </Menu>
              </>
            ) : null
          }
          toolbarSx={
            profileIconVisible
              ? {}
              : { diplay: 'flex', justifyContent: 'center', alignItems: 'center' }
          }
        />
        <SideBar
          anchor="left"
          open={sidebarOpen}
          variant={sidebarVariant}

          // onClose={onSideBarClose}
        />
      </Stack>

      <Stack
        component="main"
        sx={{
          flexGrow: 1, // Takes all possible space
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: 0,
          marginTop: TOPBAR_DESKTOP_HEIGHT,
          borderRadius: '20px 0px 0px 0px',
          background: (theme) => theme.palette.background.default,
        }}
      >
        {children}
      </Stack>
      {/* <Stack component="footer" paddingTop={4}>
        <BottomBar items={BOTTOMBAR_ITEMS} />
      </Stack> */}
    </Stack>
  );
};

export default PrivateLayout;
