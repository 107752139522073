export const hasEditPermission = (user, artist) => {
  if (!user || !artist) return false;
  return user?.editor_delegated_artists?.includes(artist?.rtsvrf_artist_id);
};

export const hasFanDataPermission = (user, artist) => {
  if (!user || !artist) return false;
  return user?.fandata_delegated_artists?.includes(artist?.rtsvrf_artist_id);
};

export const hasLabelGroupPermission = (user, artist) => {
  if (!user || !artist) return false;
  return (
    user?.is_label_group_manager &&
    user?.label_group_code &&
    artist?.share_data_with_label &&
    artist?.artist_label_connection?.includes(user?.label_group_code)
  );
};
