export const getUserSubscriberKey = async (userId) => {
  if (!userId) return;
  const options = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(
    `${process.env.REACT_APP_INTERNAL_SERVER}/subscriber-key/${userId}`,
    options
  );
  const result = await response.json();

  return result?.subscriberKey ?? null;
};

export const notifyUser = async (userId, data) => {
  if (!data) return;
  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  const result = await fetch(`${process.env.REACT_APP_INTERNAL_SERVER}/${userId}/notify`, options);
  return result;
};

export const subscribeUserToFavouriteArtist = async (userId, artistListId, artistListData) => {
  if (!userId || !artistListId) return;

  try {
    const options = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        data: {
          ...artistListData,
        },
      }),
    };
    fetch(
      `${process.env.REACT_APP_INTERNAL_SERVER}/${userId}/subscribe/artist/${artistListId}`,
      options
    )
      .then((response) => response.json())
      .then((response) => console.log(response))
      .catch((err) => console.error(err));
  } catch (error) {
    console.error('Error subscribing user to Favourite artist:', error);
  }
};

export const unsubscribeUserFromArtist = async (userId, artistId) => {
  if (!userId || !artistId) return;

  try {
    const options = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({}),
    };
    fetch(
      `${process.env.REACT_APP_INTERNAL_SERVER}/${userId}/unsubscribe/artist/${artistId}/single`,
      options
    )
      .then((response) => response.json())
      .then((response) => console.log(response))
      .catch((err) => console.error(err));
  } catch (error) {
    console.error('Error unsubscribing user from an artist:', error);
  }
};

export const subscribeUserToMultipleArtists = async (userId, artistsList) => {
  if (!userId || !artistsList || !Array.isArray(artistsList)) return;
  try {
    const options = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        artistsList,
      }),
    };
    fetch(`${process.env.REACT_APP_INTERNAL_SERVER}/${userId}/subscribe/artists`, options)
      .then((response) => response.json())
      .then((response) => console.log(response))
      .catch((err) => console.error(err));
  } catch (error) {
    console.error('Error subscribing user to multiple artists:', error);
  }
};

export const sendMessagetoFans = async (listId, data) => {
  if (!data) return;
  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      ...data,
    }),
  };
  const result = await fetch(
    `${process.env.REACT_APP_INTERNAL_SERVER}/${listId}/send-message`,
    options
  );
  return result;
};

export const createCustomList = async (listId, data) => {
  if (!data) return;
  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      ...data,
    }),
  };
  const result = await fetch(`${process.env.REACT_APP_INTERNAL_SERVER}/${listId}/create`, options);
  return result;
};
