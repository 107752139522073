import PropTypes from 'prop-types';
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { AppIcon, AppLink } from '../../components';
import { useLocation } from 'react-router';
import { PROP_TYPE_LINK_ITEM } from '../utils';

/**
 * Renders Navigation Item for SideBar, detects current url and sets selected state if needed
 * @component SideBarNavItem
 */
const SideBarNavItem = ({
  openInNewTab,
  icon,
  imageSrc,
  selectedImageSrc,
  path,
  selected: propSelected = false,
  subtitle,
  title,
  onClick,
}) => {
  const location = useLocation();
  const selected =
    propSelected ||
    (path && path !== '/' && location.pathname.startsWith(path)) ||
    location.pathname === path;
  console.log(path, location.pathname, selected);
  return (
    <ListItemButton
      component={AppLink}
      selected={selected}
      to={path}
      href="" // Hard reset for .href property, otherwise links are always opened in new tab :(
      openInNewTab={openInNewTab}
      onClick={onClick}
      sx={{
        margin: '1rem 0',
        '&.Mui-selected': {
          borderRadius: '20px',
          backgroundColor: (theme) => theme.palette.background.paper,
        },
        '&.Mui-selected:hover': {
          borderRadius: '20px',
          backgroundColor: (theme) => theme.palette.background.paper,
        },
      }}
    >
      <ListItemIcon>
        {icon && (
          <AppIcon
            sx={{
              color: (theme) => (selected ? theme.palette.info.main : theme.palette.text.primary),
            }}
            icon={icon}
          />
        )}
        {imageSrc && !selected ? (
          <img src={imageSrc} alt={title} width={'24px'} height={'24px'} />
        ) : selected && selectedImageSrc ? (
          <img src={selectedImageSrc} alt={title} width={'24px'} height={'24px'} />
        ) : null}
      </ListItemIcon>
      <ListItemText
        primary={title}
        secondary={subtitle}
        sx={{
          color: (theme) => (selected ? theme.palette.info.main : theme.palette.text.primary),
          fontSize: (theme) => theme.typography.subtitle2.fontSize,
        }}
      />
    </ListItemButton>
  );
};

SideBarNavItem.propTypes = {
  ...PROP_TYPE_LINK_ITEM,
  openInNewTab: PropTypes.bool,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
};

export default SideBarNavItem;
