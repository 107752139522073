import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Stack, Divider, Drawer, FormControlLabel, Switch, Tooltip } from '@mui/material';
import { AppIconButton } from '../../components';
import { useAppStore } from '../../store/AppStore';
import {
  useEventLogout,
  useEventSwitchDarkMode,
  useIsAuthenticated,
  useOnMobile,
} from '../../hooks';
import SideBarNavList from './SideBarNavList';
import { SIDEBAR_WIDTH, TOPBAR_DESKTOP_HEIGHT } from '../config';
import UserInfo from '../../components/UserInfo';
import { PROP_TYPE_LINK_ITEM } from '../utils';
import ArtistDelegation from './ArtistDelegation';
import {
  hasEditPermission,
  hasFanDataPermission,
  hasLabelGroupPermission,
} from '../../utils/accessControls';
import home from '../../assets/icons/sidebar/home.png';
import manage from '../../assets/icons/sidebar/edit.png';
import link from '../../assets/icons/sidebar/link.png';
import activity from '../../assets/icons/sidebar/Activity.png';
import analytics from '../../assets/icons/sidebar/diagram.png';
import message from '../../assets/icons/sidebar/Message.png';
import fanData from '../../assets/icons/sidebar/fan-data.png';
import partner from '../../assets/icons/sidebar/share.png';
import news from '../../assets/icons/sidebar/news.png';

import home_white from '../../assets/icons/sidebar/home_white.png';
import news_white from '../../assets/icons/sidebar/news_white.png';
import manage_white from '../../assets/icons/sidebar/edit_white.png';
import link_white from '../../assets/icons/sidebar/link_white.png';
import activity_white from '../../assets/icons/sidebar/Activity_white.png';
import analytics_white from '../../assets/icons/sidebar/diagram_white.png';
import message_white from '../../assets/icons/sidebar/Message_white.png';
import fanData_white from '../../assets/icons/sidebar/fan-data_white.png';
import partner_white from '../../assets/icons/sidebar/share_white.png';
import './sidebar.css';
import { SET_SELECTED_ARTIST, SET_SELECTED_ARTIST_IMAGE } from '../../store/Actions';

/**
 * Renders SideBar with Menu and User details
 * Actually for Authenticated users only, rendered in "Private Layout"
 * @component SideBar
 * @param {string} anchor - 'left' or 'right'
 * @param {array} items - list of objects to render as navigation items
 * @param {boolean} open - the Drawer is visible when true
 * @param {string} variant - variant of the Drawer, one of 'permanent', 'persistent', 'temporary'
 * @param {function} onClose - called when the Drawer is closing
 */
const SideBar = ({ anchor, items, open, variant, onClose, ...restOfProps }) => {
  const [state, dispatch] = useAppStore();
  const artist = state.selectedArtist;
  const artistImage = state.selectedArtistImage;
  // const isAuthenticated = state.isAuthenticated; // Variant 1
  const isAuthenticated = useIsAuthenticated(); // Variant 2
  const onMobile = useOnMobile();
  const [selectedArtist, setSelectedArtist] = useState(artist);
  const [selectedArtistImage, setSelectedArtistImage] = useState(artistImage);
  const userMapping = state.currentUserMapping;
  const onSwitchDarkMode = useEventSwitchDarkMode();
  const onLogout = useEventLogout();
  const [sideBarItems, setSideBarItems] = useState([
    {
      title: 'Artist Home',
      path: '/',
      selectedImageSrc: home_white,
      imageSrc: home,
    },
  ]);

  const handleAfterLinkClick = useCallback(
    (event) => {
      if (variant === 'temporary' && typeof onClose === 'function') {
        onClose(event, 'backdropClick');
      }
    },
    [variant, onClose]
  );
  useEffect(() => {
    if (userMapping && selectedArtist) {
      if (
        userMapping?.editor_delegated_artists?.length ||
        userMapping?.fandata_delegated_artists?.length ||
        (userMapping?.is_label_group_manager && userMapping?.label_group_code)
      ) {
        dispatch({ type: SET_SELECTED_ARTIST, payload: selectedArtist });
        dispatch({ type: SET_SELECTED_ARTIST_IMAGE, payload: selectedArtistImage });
        const list = [
          {
            title: 'Artist Home',
            path: '/',
            selectedImageSrc: home_white,
            imageSrc: home,
          },
        ];
        console.log(userMapping, selectedArtist);
        if (hasEditPermission(userMapping, selectedArtist)) {
          list.push({
            title: 'Manage Artist Profile',
            path: '/manage-profile',
            selectedImageSrc: manage_white,
            imageSrc: manage,
          });
          list.push({
            title: 'Manage Artist Data',
            path: '/manage-data',
            selectedImageSrc: link_white,
            imageSrc: link,
          });
          list.push({
            title: 'Artist Account Activity ',
            path: '/activity',
            selectedImageSrc: activity_white,
            imageSrc: activity,
          });
          list.push({
            title: 'Partnering',
            path: '/partnering',
            selectedImageSrc: partner_white,
            imageSrc: partner,
          });
          list.push({
            title: 'Create Artist News',
            path: '/create-post',
            selectedImageSrc: news_white,
            imageSrc: news,
          });
        }
        if (hasFanDataPermission(userMapping, selectedArtist)) {
          list.push({
            title: 'Fan Analytics',
            path: '/fan-analytics',
            selectedImageSrc: analytics_white,
            imageSrc: analytics,
          });
          list.push({
            title: 'Access Fan Data',
            path: '/fan-data',
            selectedImageSrc: fanData_white,
            imageSrc: fanData,
          });
          list.push({
            title: 'Send Fan Messages',
            path: '/messages',
            selectedImageSrc: message_white,
            imageSrc: message,
          });
        }
        if (
          hasLabelGroupPermission(userMapping, selectedArtist) &&
          !hasFanDataPermission(userMapping, selectedArtist)
        ) {
          list.push({
            title: 'Fan Analytics',
            path: '/fan-analytics',
            selectedImageSrc: analytics_white,
            imageSrc: analytics,
          });
          list.push({
            title: 'Access Fan Data',
            path: '/fan-data',
            selectedImageSrc: fanData_white,
            imageSrc: fanData,
          });
        }

        setSideBarItems(list);
      }
    } else {
      setSideBarItems([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedArtist]);

  return (
    <Drawer
      anchor={anchor}
      open={open}
      variant={variant}
      PaperProps={{
        sx: {
          background: (theme) => theme.palette.info.main,
          color: (theme) => theme.palette.text.primary,
          width: SIDEBAR_WIDTH,
          marginTop: TOPBAR_DESKTOP_HEIGHT,
          height: onMobile
            ? '100%'
            : variant === 'temporary'
              ? '100%'
              : `calc(100% - ${TOPBAR_DESKTOP_HEIGHT})`,
        },
      }}
      onClose={onClose}
    >
      <Stack
        sx={{
          height: '100%',
          padding: 2,
        }}
        {...restOfProps}
        onClick={handleAfterLinkClick}
      >
        {isAuthenticated && userMapping && (
          <>
            <ArtistDelegation
              userMapping={userMapping}
              selectedArtist={selectedArtist}
              setSelectedArtist={setSelectedArtist}
              setSelectedArtistImage={setSelectedArtistImage}
              selectedArtistImage={selectedArtistImage}
            />
          </>
        )}

        <SideBarNavList items={sideBarItems} showIcons />

        <Divider />

        {/* <Stack
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            marginTop: 2,
          }}
        >
          <Tooltip title={state.darkMode ? 'Switch to Light mode' : 'Switch to Dark mode'}>
            <FormControlLabel
              label={!state.darkMode ? 'Light mode' : 'Dark mode'}
              control={<Switch checked={state.darkMode} onChange={onSwitchDarkMode} />}
            />
          </Tooltip>

          {isAuthenticated && (
            <AppIconButton icon="logout" title="Logout Current User" onClick={onLogout} />
          )}
        </Stack> */}
      </Stack>
    </Drawer>
  );
};

SideBar.propTypes = {
  anchor: PropTypes.oneOf(['left', 'right']),
  className: PropTypes.string,
  open: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.shape(PROP_TYPE_LINK_ITEM)),
  variant: PropTypes.oneOf(['permanent', 'persistent', 'temporary']),
  onClose: PropTypes.func,
};

export default SideBar;
